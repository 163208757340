
import { getSDBaseAPI } from '@/configs';
import request from '@/utils/request';
import yihuiRequest from '@/utils/yihuiRequest';
import { useCancelGenerateInfoStore } from '@/zustand';
import { md5 } from 'js-md5';
import { getLoginActiveInfo } from '@/utils/auth';

// yihui.art 
// yihui.art 用户信息
export async function fetchUserInfo2(params?: Record<string, unknown>): Promise<unknown> {
  let url='/api/user/center'
  // let url='https://aide-admin.yihui.art/api/user/center'
  return await yihuiRequest.get(`${url}`);
}

// yihui.art 用户信息
export async function fetchUserInfo3(params?: Record<string, unknown>): Promise<unknown> {
  let url = '/api/user/center'
  const { loginActive, timestamp = new Date().getTime(), platform } = getLoginActiveInfo()
  let headers = {}
  // if (loginActive == '3') {
  //   headers = {
  //     platform: 'yiihuu',
  //     signature: md5(`!&jeru@kgknv4&5#ouftimestamp=${timestamp}&platform=${platform}`),
  //     timestamp:timestamp
  //   }
  // }
  // let url='https://aide-admin.yihui.art/api/user/center'
  return await yihuiRequest.get(`${url}`, {
    ...params,
    headers,
  });
}

// 获取会员列表
export async function fetchMemberList(params?: Record<string, unknown>): Promise<unknown> {
  let url = '/api/member/lists'
  const { loginActive, timestamp = new Date().getTime(), platform } = getLoginActiveInfo()
  let headers = {}
  // if (loginActive == '3') {
  //   headers = {
  //     platform: 'yiihuu',
  //     signature: md5(`!&jeru@kgknv4&5#ouftimestamp=${timestamp}&platform=${platform}`),
  //     timestamp:timestamp
  //   }
  // }
  return await yihuiRequest.get(`${url}`, {
    params,
    headers
  });
}

// 立即购买下单
export async function fetchVipBuy(params?: Record<string, unknown>): Promise<unknown> {
  let url = '/api/member/buy'
  const { loginActive, timestamp = new Date().getTime(), platform } = getLoginActiveInfo()
  let headers = {}
  // if (loginActive == '3') {
  //   headers = {
  //     platform: 'yiihuu',
  //     signature: md5(`!&jeru@kgknv4&5#ouftimestamp=${timestamp}&platform=${platform}`),
  //     timestamp:timestamp
  //   }
  // }
  return await yihuiRequest.post(`${url}`, {
    ...params,
  },{headers});
}

// 会员下单
// export const fetchVipBuy = (data) => request.post('/api/member/buy', data)

// 预支付-微信二维码/支付宝支付
export async function fetchPrepay(params?: Record<string, unknown>): Promise<unknown> {
  let url = '/api/pay/prepay'
  const { loginActive, timestamp = new Date().getTime(), platform } = getLoginActiveInfo()
  let headers = {}
  // if (loginActive == '3') {
  //   headers = {
  //     platform: 'yiihuu',
  //     signature: md5(`!&jeru@kgknv4&5#ouftimestamp=${timestamp}&platform=${platform}`),
  //     timestamp:timestamp
  //   }
  // }
  return await yihuiRequest.post(`${url}`, {
    ...params,
  },{headers});
}

// 微信支付-轮询监听 
export async function fetchPayStatus(params?: Record<string, unknown>): Promise<unknown> {
  let url = '/api/pay/payStatus'
  const { loginActive, timestamp = new Date().getTime(), platform } = getLoginActiveInfo()
  let headers = {}
  // if (loginActive == '3') {
  //   headers = {
  //     platform: 'yiihuu',
  //     signature: md5(`!&jeru@kgknv4&5#ouftimestamp=${timestamp}&platform=${platform}`),
  //     timestamp:timestamp
  //   }
  // }
  return await yihuiRequest.get(`${url}`, {
    params,
    headers
  });
}

// 获取图库 
export async function fetchBatchImgIndex(params?: Record<string, unknown>): Promise<unknown> {
  let url = '/api/draw_records/records'
  const { loginActive, timestamp = new Date().getTime(), platform } = getLoginActiveInfo()
  let headers = {}
  // if (loginActive == '3') {
  //   headers = {
  //     platform: 'yiihuu',
  //     signature: md5(`!&jeru@kgknv4&5#ouftimestamp=${timestamp}&platform=${platform}`),
  //     timestamp:timestamp
  //   }
  // }
  return await yihuiRequest.get(`${url}`, {
    params,
    headers
  });
}

// 删除图库图片
export async function fetchImgDelete(params) {
  let url = '/api/draw_records/deleteImg'
  const { loginActive, timestamp = new Date().getTime(), platform } = getLoginActiveInfo()
  let headers = {}
  // if (loginActive == '3') {
  //   headers = {
  //     platform: 'yiihuu',
  //     signature: md5(`!&jeru@kgknv4&5#ouftimestamp=${timestamp}&platform=${platform}`),
  //     timestamp:timestamp
  //   }
  // }
  return await yihuiRequest.post(`${url}`, {
    ...params,
  });
}

