import { getSDBaseAPI } from '@/configs';
import request from '@/utils/request';
import yihuiRequest from '@/utils/yihuiRequest';
import { useCancelGenerateInfoStore } from '@/zustand';
import { md5 } from 'js-md5';
import axios from 'axios';
import { getLoginActiveInfo } from '@/utils/auth';

export async function listSDModels(): Promise<unknown[]|undefined> {
  const sdBaseUrl = getSDBaseAPI();
  if (!sdBaseUrl) {
    return;
  }
  const response = await request.get(`${sdBaseUrl}/sdapi/v1/sd-models`);
  return response;
}

export async function fetchAdModel(): Promise<unknown[]|undefined> {
  const sdBaseUrl = getSDBaseAPI();
  if (!sdBaseUrl) {
    return;
  }
  const response = await request.get(`${sdBaseUrl}/adetailer/v1/ad_model`);
  return response;
}

export async function listLoras(): Promise<unknown[]|undefined> {
  const sdBaseUrl = getSDBaseAPI();
  if (!sdBaseUrl) {
    return;
  }
  const response = await request.get(`${sdBaseUrl}/sdapi/v1/loras`);
  return response;
}

export async function listSDVAEs(): Promise<unknown[]|undefined> {
  const sdBaseUrl = getSDBaseAPI();
  if (!sdBaseUrl) {
    return;
  }
  return await request.get(`${sdBaseUrl}/sdapi/v1/sd-vae`);
}

// 文生图
export async function txt2img(params: Record<string, unknown>, ): Promise<unknown> {
  const sdBaseUrl = getSDBaseAPI();
  if (!sdBaseUrl) {
    return;
  }
  const CancelToken = axios.CancelToken;
  const cancelGenerateInfo = CancelToken.source();
  const setCancelCreatePlan = useCancelGenerateInfoStore.setState;
  setCancelCreatePlan({cancelGenerateInfo:cancelGenerateInfo});
  const response = await request.post(`${sdBaseUrl}/sdapi/v1/txt2img`, {
    ...params
  },{headers:{'Request-Type':'async'}, cancelToken:cancelGenerateInfo.token });
  return response;
}

// 图生图
export async function img2img(params: Record<string, unknown>): Promise<unknown> {
  const sdBaseUrl = getSDBaseAPI();
  if (!sdBaseUrl) {
    return;
  }
  const CancelToken = axios.CancelToken;
  const cancelGenerateInfo = CancelToken.source();
  const setCancelCreatePlan = useCancelGenerateInfoStore.setState;
  setCancelCreatePlan({cancelGenerateInfo:cancelGenerateInfo});
  const response = await request.post(`${sdBaseUrl}/sdapi/v1/img2img`, {
    ...params
  },{headers:{'Request-Type':'async'}, cancelToken:cancelGenerateInfo.token});
  return response;
}
// 同步图生图
export async function img2imgsync(params: Record<string, unknown>): Promise<unknown> {
  const sdBaseUrl = getSDBaseAPI();
  if (!sdBaseUrl) {
    return;
  }
  const response = await request.post(`${sdBaseUrl}/sdapi/v1/img2img`, {
    ...params
  });
  return response;
}
// controlNet 生成预览
export async function controlnetDetect(params: Record<string, unknown>): Promise<unknown> {
  const sdBaseUrl = getSDBaseAPI();
  if (!sdBaseUrl) {
    return;
  }
  const response = await request.post(`${sdBaseUrl}/controlnet/detect`, {
    ...params
  });
  return response;
}

// 图片生成进度
export async function progressTask(params: Record<string, unknown>,cancelToken): Promise<unknown> {
  const sdBaseUrl = getSDBaseAPI();
  if (!sdBaseUrl) {
    return;
  }
  return await request.get(`${sdBaseUrl}/sdapi/v1/progress-task`, { params, ...cancelToken });
}

// 获取Control 模型列表
export async function controlnetTypes(params?: Record<string, unknown>): Promise<unknown> {
  const sdBaseUrl = getSDBaseAPI();
  if (!sdBaseUrl) {
    return;
  }
  return await request.get(`${sdBaseUrl}/controlnet/control_types`);
}

// 获取 文生图 Upscalers 模型列表
export async function fetchGoyorUpscalers(params?: Record<string, unknown>): Promise<unknown> {
  const sdBaseUrl = getSDBaseAPI();
  if (!sdBaseUrl) {
    return;
  }
  return await request.get(`${sdBaseUrl}/sdapi/v1/goyor-upscale`,params);
}
// 获取 Upscalers 模型列表
export async function fetchUpscalers(params?: Record<string, unknown>): Promise<unknown> {
  const sdBaseUrl = getSDBaseAPI();
  if (!sdBaseUrl) {
    return;
  }
  return await request.get(`${sdBaseUrl}/sdapi/v1/upscalers`);
}

// 生成后期处理
export async function fetchExtraSingleImage(params: Record<string, unknown>): Promise<unknown> {
 
  const sdBaseUrl = getSDBaseAPI();
  if (!sdBaseUrl) {
    return;
  }
  return await request.post(`${sdBaseUrl}/sdapi/v1/extra-single-image`, {
    ...params
  });
   
}

// 获取点数
export async function fetchPoints(params?: Record<string, unknown>): Promise<unknown> {
  const sdBaseUrl = getSDBaseAPI();
  if (!sdBaseUrl) {
    return;
  }
  return await request.get(`${sdBaseUrl}/api/aigc/get_points`);
}

// 获取OSS 配置
export async function fetchOssConfig(params?: Record<string, unknown>): Promise<unknown> {
  const sdBaseUrl = getSDBaseAPI();
  if (!sdBaseUrl) {
    return;
  }
  return await request.get(`${sdBaseUrl}/api/upload/oss_config`);
}

// 翻译
export async function translate(params: Record<string, unknown>): Promise<unknown> {
  const sdBaseUrl = getSDBaseAPI();
  if (!sdBaseUrl) {
    return;
  }
  return await request.post(`${sdBaseUrl}/thirdapi/txt/translate`, {
    ...params
  });
}

/* png 图片信息
  {
    "image": "string"
  }
*/
export async function pngInfo(params: Record<string, unknown>): Promise<unknown> {
  const sdBaseUrl = getSDBaseAPI();
  if (!sdBaseUrl) {
    return;
  }
  const response = await request.post(`${sdBaseUrl}/sdapi/v1/png-info`, {
    ...params
  });
  return response;
}

// 上报反馈
export async function fetchFeedBack(params: Record<string, unknown>): Promise<unknown> {
  const sdBaseUrl = import.meta.env.VITE_APP_FEED_BACK;
  if (!sdBaseUrl) {
    return;
  }
  const response = await request.post(`${sdBaseUrl}/api/feedback/add`, {
    ...params
  });
  return response;
}

