import axios, { AxiosHeaders,AxiosRequestConfig  } from 'axios';
import { message } from 'antd';
import { getLoginActiveInfo, getToken, removeToken } from './auth'
import { BaseResponse } from "@/types/common/request"
import { useLoginStore, useSelectorStore, useYihuiLoginStore } from '@/zustand';
import { md5 } from 'js-md5';
const DEFAULT_ERROR_CODE = 10000;


function strToJson(config) { //将config内data 或 params转成json返回
  let params = {};
  if (config.method == 'get' && config.params) {
    params = typeof config.params == 'string' && config.params.includes('{') ? JSON.parse(config.params) : config.params;
  } else if (config.data) {
    params = typeof config.data == 'string' && config.data.includes('{') ? JSON.parse(config.data) : config.data;
  }
  return params
}

// let HREF_API = import.meta.env.VITE_YIHUI_BASE_API
const instance = axios.create({
  baseURL: import.meta.env.VITE_YIHUI_BASE_API,
  timeout: 200000,
});

const request = async (config: AxiosRequestConfig) => {
  const { data } = await instance(config)
  return data
}

let modalRef;
export async function login(params: { username: string, password: string }): Promise<any> {
  const { username, password } = params;

  return instance ('/login/doLoginByPwd', {
    method: 'POST', data: {
      username,
      password: btoa(password),
    }
  });
}

export class BizError extends Error {
  code: number;
  constructor(message: string, code: number = DEFAULT_ERROR_CODE) {
    super(message);
    this.name = 'BizError';
    this.code = code;
  }
}

function getCookie(name) {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) {
    const cookie = parts.pop()!.split(';').shift();
    return cookie;
  }
  return;
}

instance.interceptors.request.use((config) => {
  // const token = 'Bearer ' + getToken();
  const token = getToken();
    const { loginActive, timestamp = new Date().getTime(), platform } = getLoginActiveInfo();
    let YiihuuHeaders = {};
    if (loginActive == '3') {
      YiihuuHeaders = {
        platform: 'yiihuu',
        signature: md5(`!&jeru@kgknv4&5#ouftimestamp=${timestamp}&platform=${platform}`),
        timestamp:timestamp,
      }
    }
  const headers = new AxiosHeaders({ ...config.headers, 'token': token!, ...YiihuuHeaders});
  config.headers = headers;
  return config;
});

// 添加响应拦截器
instance.interceptors.response.use(
  (response) => {
    const res = response.data;
    // console.log(res);
    let {code,msg}=res
    if(code==-1){//登录过期
      const { removeStoreToken } = useSelectorStore.getState();
      const { setLoginVisible } = useLoginStore.getState();
      removeStoreToken();
      setLoginVisible(true);
      return;
    }else if(code!=1){//其它异常
      let params = strToJson(response.config);
      // console.log(params);
      if (params.show_error_msg == false) {
        return res
      }else{
        message.error(msg);
        // return;
        return Promise.reject(new Error(res.msg || 'Error'))
      }
    }
    
    return res
  },
  (err) => {
    // 对响应错误做点什么
    return Promise.reject(new BizError(err.message));
  },
);

export default instance;
